import { defineStore } from "pinia";
import http from "./http.js";
export const useInvestigationGeneratedDocumentStore = defineStore("ingestigationgenerateddocument", { 
  state: () => ({
    requestStatus: "",
    error: "",
    ingestigationgenerateddocuments: [],
    ingestigationgenerateddocument: null,
  }),

  getters: {
    getRequestStatus(state) {
      return state.requestStatus;
    },
    getItems(state) {
      return state.ingestigationgenerateddocuments;
    },
    getItem(state) {
      return state.ingestigationgenerateddocument;
    },
  },

  actions: {
    index() {
      this.requestStatus = "LOADING";
      return http
        .get("ingestigationgenerateddocuments")
        .then((response) => {
          this.ingestigationgenerateddocuments = response.data;
          this.requestStatus = "SUCCESS";
        })
        .catch((error) => {
          this.error = error;
          this.requestStatus = "FAILED";
        });
    },
    store(data) {
      this.requestStatus = "LOADING";
      return http
        .post("ingestigationgenerateddocuments", data)
        .then((response) => {
          this.ingestigationgenerateddocument = response.data;
          this.requestStatus = "SUCCESS";
        })
        .catch((error) => {
          this.error = error;
          this.requestStatus = "FAILED";
        });
    },
    show(id) {
      this.requestStatus = "LOADING";
      return http
        .get("ingestigationgenerateddocuments/" + id)
        .then((response) => {
          this.ingestigationgenerateddocument = response.data;
          this.requestStatus = "SUCCESS";
        })
        .catch((error) => {
          this.error = error;
          this.requestStatus = "FAILED";
        });
    },
    update(data) {
      this.requestStatus = "LOADING";
      return http
        .put("ingestigationgenerateddocuments", data)
        .then((response) => {
          this.ingestigationgenerateddocument = response.data;
          this.requestStatus = "SUCCESS";
        })
        .catch((error) => {
          this.error = error;
          this.requestStatus = "FAILED";
        });
    },
    delete(id) {
      this.requestStatus = "LOADING";
      return http
        .delete("ingestigationgenerateddocuments/" + id)
        .then(() => {
          this.requestStatus = "SUCCESS";
        })
        .catch((error) => {
          this.error = error;
          this.requestStatus = "FAILED";
        });
    },
  },
});

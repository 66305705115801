import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [

  // {
  //   path: "/login",
  //   name: "home",
  //   component: () => import("../views/authentication/LoginPage.vue"),
  // },

  {
    path: "/toto",
    name: "page-toto",
    component: () => import("../views/Toto/TotoPage.vue"),
    meta: {
      requiresAuth: false,
    },
  },

  {
    path: "/public/audiences",
    name: "public-audiences",
    component: () => import("../views/publicAccess/AudienceAccess.vue"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/localities",
    name: "localities",
    component: () => import("../views/localities/LocalityPage.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/positions",
    name: "positions",
    component: () => import("../views/positions/PositionPage.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/positions/:id/access",
    name: "position_access",
    component: () => import("../views/positions/PositionAccess.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/referrals",
    name: "referrals",
    component: () => import("../views/referrals/ReferralPage.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/referral_methods",
    name: "referral_methods",
    component: () => import("../views/referralMedthods/ReferralMethodPage.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/chambers",
    name: "chambers",
    component: () => import("../views/chambers/ChamberPage.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/chambers/:id/features",
    name: "chamber_features",
    component: () => import("../views/chambers/ChamberFeaturePage.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/jurisdiction_types",
    name: "jurisdiction_types",
    component: () =>
      import("../views/jurisdictionTypes/JurisdictionTypePage.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/jurisdictions",
    name: "jurisdictions",
    component: () => import("../views/jurisdictions/JurisdictionPage.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/affairs",
    name: "affairs",
    component: () => import("../views/affairs/AffairPage.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/features",
    name: "features",
    component: () => import("../views/features/FeaturePage.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/application_status",
    name: "application_status",
    component: () =>
      import("../views/applicationStatus/ApplicationStatusPage.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/users",
    name: "users",
    component: () => import("../views/users/UserPage.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/users/:id/reset_password",
    name: "reset_password",
    component: () => import("../views/resetPassword/ResetPasswordPage.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/admins",
    name: "admins",
    component: () => import("../views/admins/AdminPage.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/trames",
    name: "trames",
    component: () => import("../views/trames/TramePage.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/trames/:id/derivation",
    name: "trame_derivations",
    component: () => import("../views/trames/TrameDerivationPage.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/trames/:id/profiles",
    name: "trame_profiles",
    component: () => import("../views/trames/TrameProfilePage.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/trames/:id/documents",
    name: "trame_documents",
    component: () => import("../views/trames/TrameDocumentPage.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/authentication/LoginPage.vue"),
  },
  {
    path: "/admin/login",
    name: "admin_login",
    component: () => import("../views/authentication/AdminLoginPage.vue"),
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: () => import("../views/dashboard/DashboardPage.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/statistics",
    name: "statistics",
    component: () => import("../views/statistic/StatisticPage.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/chain_selection",
    name: "chain_selection",
    component: () => import("../views/chainSelection/ChainSelectionPage.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/repport_types",
    name: "repport_types",
    component: () => import("../views/repportTypes/RepportTypePage.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/attachment_types",
    name: "attachment_types",
    component: () => import("../views/attachmentTypes/AttachmentTypePage.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/attachment_types/:id/templates",
    name: "attachment_type_templates",
    component: () =>
      import("../views/judgementFileTemplates/JudgementFileTemplatePage.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/audience_types",
    name: "audience_types",
    component: () => import("../views/audienceTypes/AudienceTypePage"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/audience_results/:id/templates",
    name: "audience_result_templates",
    component: () =>
      import("../views/audienceResultTemplates/AudienceResultTemplatePage"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/audience_results/:id/documents",
    name: "audience_result_documents",
    component: () => import("../views/audienceResults/AudienceResultDocumentPage.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/audience_results",
    name: "audience_results",
    component: () => import("../views/audienceResults/AudienceResultPage"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/audiences",
    name: "audiences",
    component: () => import("../views/audiences/AudiencePage"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/trame_actions/:id//:investigationId?",
    name: "trame_actions",
    component: () => import("../views/trameActions/TrameActions.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/investigation_registrations",
    name: "investigation_registrations",
    component: () => import("../views/investigations/FileRegistrationPage.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/investigation_processes/:id?",
    name: "investigation_processes",
    component: () =>
      import("../views/investigationProcesses/InvestigationProcessPage.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/investigations/:investigationId/:processId",
    name: "investigations",
    component: () => import("../views/investigations/InvestigationPage.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/investigation_events",
    name: "investigation_events",
    component: () =>
      import("../views/investigationEvents/InvestigationEventPage.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/nations",
    name: "nations",
    component: () => import("../views/nations/NationPage.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/person_types",
    name: "person_types",
    component: () => import("../views/personTypes/PersonTypePage.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/persons",
    name: "persons",
    component: () => import("../views/persons/PersonPage.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/person_status_in_investigations",
    name: "person_status_in_investigations",
    component: () =>
      import(
        "../views/personStatusInInvestigations/PersonStatusInInvestigationPage.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/person_situation_in_investigations",
    name: "person_situation_in_investigations",
    component: () =>
      import(
        "../views/personSituationInInvestigations/PersonSituationInInvestigationPage.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/speakers",
    name: "speakers",
    component: () =>
      import(
        "../views/speakers/SpeakerPage.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/settings",
    name: "settings",
    component: () =>
      import(
        "../views/settings/SettingPage.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/document-models",
    name: "document-models",
    component: () =>
      import(
        "../views/DocumentModels/DocumentModels.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/",
    name: "home",
    component: () =>
      import(
        "../views/home/HomePage.vue"
      ),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const isAuthenticated = !!localStorage.getItem("token"); // Vérifiez ici si l'utilisateur est authentifié
  if (to.meta.requiresAuth && !isAuthenticated) {
    // Rediriger vers la page de connexion si l'utilisateur n'est pas authentifié
    next({ path: "/login" });
  } else if (!to.meta.requiresAuth && isAuthenticated) {
    // Rediriger vers le tableau de bord si l'utilisateur est déjà authentifié
    //next({ path: "/dashboard" });
    next();
  } else {
    // Permettre la navigation normalement
    next();
  }
});

export default router;

<template>
  <div class="w-100">
    <div v-if="documents.length" class="border rounded rounded-3 p-3 w-100">
      <h4 class="text-primary py-2">{{ $t("documents_to_generate_or_edit") }}</h4>
      <div>
        <div v-for="document in documents" :key="document.id" class="d-flex flex-row">
          <small>{{ document.documentModelName }}</small>
          <el-button  v-if="isDocumentAlreadyGenerated(document) == null"
            @click="generateDocument(document)"
            style="color: blue !important"
            class="mx-5"
            type="text"
            size="large"
            >{{ $t("generate_document") }}</el-button
          >
          <el-button  v-else
            @click="updateDocument(document)"
            style="color: blue !important"
            class="mx-5"
            type="text"
            size="large"
            >{{ $t("update_document") }}</el-button
          >
        </div>
      </div>
    </div>

    <el-dialog title="" :visible.sync="showDocumentGeneration" width="70%" destroy-on-close>
      <document-geneneration-component
        :investigation="investigation"
        :investigationApi="investigationApi"
        :document="document"
        :injections="injections"
        @change="closeDialog"
      />
    </el-dialog>

  </div>
</template>
<script>
import DocumentGenenerationComponent from "./components/DocumentGenenerationComponent.vue";
export default {
  props: ["investigation", "investigationApi", "trame", "trameApi"],
  components: {
    DocumentGenenerationComponent,
  },
  inject: ['injections'],
  data() {
    return {
      investigationDocuments: [],
      documents: [],
      document: null,
      showDocumentGeneration: false,
    };
  },
  created() {
    console.log(this.injections)
    this.getInvestigationDocuments(),
    this.getDocuments();
  },
  methods: {
    getDocuments() {
      this.trameApi.fetchDocuments(this.$route.params.id).then(() => {
        this.documents = this.trameApi.getDocuments;
      });
    },
    getInvestigationDocuments() {
      this.loading = true;
      this.investigationApi.fetchDocuments(this.investigation.id).then(() => {
        this.loading = false;
        this.investigationDocuments = this.investigationApi.getDocuments;
      });
    },
    generateDocument(document) {
      this.document = document;
      this.showDocumentGeneration = true;
    },
    updateDocument(document) {
      this.document = this.isDocumentAlreadyGenerated(document);
      this.document.documentModelContent =  this.document.content;
      this.showDocumentGeneration = true;
    },
    closeDialog() {
      this.document = null;
      this.showDocumentGeneration = false;
    },
    isDocumentAlreadyGenerated(document) {
      return this.investigationDocuments.find(doc => doc.documentModelId === document.documentModelId) || null;
    }
  },
};
</script>

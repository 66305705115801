import { defineStore } from "pinia";
import http from "./http.js";

export const useTrameStore = defineStore("trame", {
  state: () => ({
    requestStatus: "",
    error: "",
    trames: [],
    trame: null,
    derivedTrames: [],
    derivedTrame: null,
    profiles: [],
    documents: [],
    totalElements: 0, // Pour la pagination
    totalPages: 0, // Pour la pagination
    currentPage: 0, // Page actuelle
    pageSize: 10, // Taille de la page
  }),

  getters: {
    getRequestStatus(state) {
      return state.requestStatus;
    },
    getItems(state) {
      return state.trames;
    },
    getItem(state) {
      return state.trame;
    },
    getDerivedTrames(state) {
      return state.derivedTrames;
    },
    getDerivedTrame(state) {
      return state.derivedTrame;
    },
    getProfiles(state) {
      return state.profiles;
    },
    getDocuments(state) {
      return state.documents;
    },
    getTotalElements(state) {
      return state.totalElements;
    },
    getTotalPages(state) {
      return state.totalPages;
    },
    getCurrentPage(state) {
      return state.currentPage;
    },
    getPageSize(state) {
      return state.pageSize;
    }
  },

  actions: {
    async index(page = 0, size = 1000, searchQuery = "") {
      this.requestStatus = "LOADING";
      try {
        const response = await http.get(
          `trames?page=${page}&size=${size}&search=${searchQuery}`
        );
        const {data, currentPage, totalPages, totalElements, pageSize  } = response.data;
        this.trames = data;
        this.totalElements = totalElements;
        this.totalPages = totalPages;
        this.currentPage = currentPage;
        this.pageSize = pageSize;
        this.requestStatus = "SUCCESS";
      } catch (error) {
        this.handleHttpError(error);
      }
    },

    async store(data) {
      this.requestStatus = "LOADING";
      return http
        .post("trames", data)
        .then((response) => {
          this.trame = response.data;
          this.requestStatus = "SUCCESS";
        })
        .catch((error) => {
          this.handleHttpError(error);
        });
    },
    show(id) {
      this.requestStatus = "LOADING";
      return http
        .get("trames/" + id)
        .then((response) => {
          this.trame = response.data;
          this.requestStatus = "SUCCESS";
        })
        .catch((error) => {
          this.handleHttpError(error);
        });
    },
    update(data) {
      this.requestStatus = "LOADING";
      return http
        .put("trames", data)
        .then((response) => {
          this.trame = response.data;
          this.requestStatus = "SUCCESS";
        })
        .catch((error) => {
          this.handleHttpError(error);
        });
    },
    delete(id) {
      this.requestStatus = "LOADING";
      return http
        .delete("trames/" + id)
        .then(() => {
          this.requestStatus = "SUCCESS";
        })
        .catch((error) => {
          this.handleHttpError(error);
        });
    },
    fetchDerivedTrames(id) {
      this.requestStatus = "LOADING";
      return http
        .get("trames/" + id + "/derivedTrames")
        .then((response) => {
          this.derivedTrames = response.data;
          this.requestStatus = "SUCCESS";
        })
        .catch((error) => {
          this.handleHttpError(error);
        });
    },
    addDerivedTrame(id, data) {
      this.requestStatus = "LOADING";
      return http
        .post("trames/" + id + "/derivedTrames", data)
        .then((response) => {
          this.derivedTrame = response.data;
          this.requestStatus = "SUCCESS";
        })
        .catch((error) => {
          this.handleHttpError(error);
        });
    },
    deleteDerivedTrame(trameId, derivedTrameId) {
      this.requestStatus = "LOADING";
      return http
        .delete("trames/" + trameId + "/derivedTrames/" + derivedTrameId)
        .then(() => {
          this.requestStatus = "SUCCESS";
        })
        .catch((error) => {
          this.handleHttpError(error);
        });
    },
    fetchProfiles(id) {
      this.requestStatus = "LOADING";
      return http
        .get("trames/" + id + "/profiles")
        .then((response) => {
          this.profiles = response.data;
          this.requestStatus = "SUCCESS";
        })
        .catch((error) => {
          this.handleHttpError(error);
        });
    },
    fetchDocuments(id) {
      this.requestStatus = "LOADING";
      return http
        .get("trames/" + id + "/documents")
        .then((response) => {
          this.documents = response.data;
          this.requestStatus = "SUCCESS";
        })
        .catch((error) => {
          this.handleHttpError(error);
        });
    },
    handleHttpError(error) {
      this.requestStatus = "FAILED";
      if (error.response) {
        // Server responded with a status code
        this.error = error.response.data.message || "An error occurred";
      } else if (error.request) {
        // Request was made but no response was received
        this.error = "No response received from server";
      } else {
        // An error occurred in setting up the request
        this.error = error.message || "An error occurred";
      }
    }
  }
});

import "@babel/polyfill";
import "mutationobserver-shim";
import Vue from "vue";
import "./plugins/bootstrap-vue";
import App from "./App.vue";
import { createPinia, PiniaVuePlugin } from "pinia";
import VueCompositionAPI from "@vue/composition-api";
import i18n from "./i18n";
import router from "./router";
import Vuesax from "vuesax";

import "vuesax/dist/vuesax.css"; //Vuesax styles
import "material-icons/iconfont/material-icons.css";
Vue.use(Vuesax, {
  // options here
});



import VueSidebarMenu from "vue-sidebar-menu";
import "vue-sidebar-menu/dist/vue-sidebar-menu.css";
Vue.use(VueSidebarMenu);

import "font-awesome/css/font-awesome.css";
import ElementUI from "element-ui";
import locale from "element-ui/lib/locale/lang/fr";
import "./styles.scss";

Vue.use(ElementUI, { locale });

// Vue.use(require("vue-moment"));
const moment = require('moment')
require('moment/locale/fr')
 
Vue.use(require('vue-moment'), {
    moment
})


import EditorComponent from "./components/EditorComponent/EditorComponent.vue";
Vue.component("EditorComponent", EditorComponent);

import ReceiverComponent from "./components/ReceiverComponent/ReceiverComponent.vue";
Vue.component("ReceiverComponent", ReceiverComponent);

import TopMenuComponent from "./components/TopMenuComponent/TopMenuComponent.vue";
Vue.component("TopMenuComponent", TopMenuComponent);

import ActionDocumentsComponent from "./components/ActionDocumentsComponent/ActionDocumentsComponent.vue";
Vue.component("ActionDocumentsComponent", ActionDocumentsComponent);

import SidebarComponent from "./components/SidebarComponent/SidebarComponent.vue";
Vue.component("SidebarComponent", SidebarComponent);


import FooterComponent from "./components/FooterComponent/FooterComponent.vue";
Vue.component("FooterComponent", FooterComponent);

import FileTransmissionComponent from "./components/FileTransmissionComponent/FileTransmissionComponent.vue";
Vue.component("FileTransmissionComponent", FileTransmissionComponent);

Vue.use(PiniaVuePlugin);
const pinia = createPinia();
Vue.use(VueCompositionAPI);


Vue.config.productionTip = false;
import VueApexCharts from "vue-apexcharts";
Vue.use(VueApexCharts);
Vue.component("apex-chart", VueApexCharts);

new Vue({
  pinia,
  i18n,
  router,
  render: (h) => h(App),
}).$mount("#app");

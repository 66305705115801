// fr_FR.js (vous pouvez le créer dans votre dossier `src/assets/langs`)
export default {
    "Cut": "Couper",
    "Copy": "Copier",
    "Paste": "Coller",
    "Undo": "Annuler",
    "Redo": "Rétablir",
    "Bold": "Gras",
    "Italic": "Italique",
    "Underline": "Souligné",
    "Strikethrough": "Barré",
    "Subscript": "Indice",
    "Superscript": "Exposant",
    "AlignLeft": "Aligner à gauche",
    "AlignCenter": "Centrer",
    "AlignRight": "Aligner à droite",
    "Justify": "Justifier",
    "Insert": "Insérer",
    "Image": "Image",
    "Link": "Lien",
    // Ajoutez d'autres traductions selon vos besoins
  };
  